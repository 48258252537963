<template>
  <div class="people-list">
    <!-- 查詢 -->
    <section class="table-search">
      <div class="search-L">
        <el-input v-model="tableParams.kw" placeholder="请输入昵称/手机号搜索" @input="kwChange" clearable class="mr15" />
        <el-select v-model="tableParams.status" placeholder="请选择状态" @change="reload" clearable>
          <el-option v-for="item in ObjToOpt(peopleStatusObj)" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="search-R">
        <el-button type="primary" @click="onTime()">设置上班时间</el-button>
        <el-button type="primary" @click="onAdd()">新增服务人员</el-button>
      </div>
    </section>
    <!-- 表格 -->
    <el-table :data="tableData" border stripe :header-cell-style="{
      background: '#FAFAFA', color: '#000000'
    }">
      <el-table-column label="创建时间" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.addDt || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="昵称" align="center" class-name="nike-name">
        <template slot-scope="scope">
          <el-image class="clear-head" :src="scope.row.headImgUrl || userAvatar"
            :preview-src-list="[scope.row.headImgUrl || userAvatar]"></el-image>
          <span>{{ scope.row.nickName || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="联系方式" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.mobile || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态" align="center">
        <template slot-scope="scope">
          <span :style="{ 'color': peopleStatusColor[scope.row.status] }">{{ scope.row.status == 1 ? "启用" : "停用" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="绑定状态" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.bindStatus ? "已绑定" : "未绑定" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="text" size="medium" @click.stop="onStart(scope.row)"
            v-if="scope.row.status == 2">启用</el-button>
          <el-button type="text" size="medium" @click.stop="onStop(scope.row)" v-if="scope.row.status == 1">停用</el-button>
          <el-button type="text" size="medium" @click.stop="onEdit(scope.row)">编辑</el-button>
          <el-button type="text" size="medium" @click.stop="onBind(scope.row)">扫码绑定</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <section class="table-footer">
      <el-pagination layout="total,sizes, prev, pager, next,jumper" background :total="total"
        :page-size="tableParams.pageSize" @size-change="onSizeChange" :page-sizes="[10, 20, 50, 100]"
        :current-page.sync="currentPage" @current-change="onCurrentPage">
      </el-pagination>
    </section>
    <!-- 编辑弹框 -->
    <el-dialog :title="editTitle" :visible.sync="editShow" :close-on-click-modal="false" append-to-body width="30%">
      <!-- 表单提交 -->
      <el-form :model="params" :rules="rules" ref="form-ref" label-width="100px">
        <section class="form-main">
          <el-form-item label="昵称" prop="nickName">
            <el-input v-model.trim="params.nickName" autocomplete="off" maxlength="8" placeholder="请填写昵称"></el-input>
          </el-form-item>
          <el-form-item label="状态" prop="status">
            <el-radio-group v-model="params.status">
              <el-radio v-for="item in ObjToOpt(peopleStatusObj)" :key="item.value" :label="item.value">{{ item.label
              }}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="联系方式" prop="mobile">
            <el-input v-model.trim="params.mobile" autocomplete="off" type="number" maxlength="16"
              placeholder="请填写联系方式"></el-input>
          </el-form-item>
        </section>
        <section class="form-footer">
          <el-button type="primary" @click="onSubmit('form-ref')">保存</el-button>
        </section>
      </el-form>
    </el-dialog>
    <!-- 绑定弹框 -->
    <el-dialog title="扫码绑定" :visible.sync="bindShow" :close-on-click-modal="false" append-to-body width="15%">
      <div class="code">
        <p>请{{ params.nickName }}使用微信扫码关注公众号</p>
        <vue-qr :logoSrc="imageUrl" :text="codeText" :size="200"></vue-qr>
        <el-button type="primary" @click="onFollowed">我已关注</el-button>
      </div>
    </el-dialog>
    <!-- 设置上班时间弹框 -->
    <el-dialog title="设置上班时间" :visible.sync="timeShow" :close-on-click-modal="false" append-to-body width="30%">
      <el-form :model="advance" :rules="rules" ref="form-time" label-width="100px">
        <section class="form-main">
          <!-- 上班时间 -->
          <el-form-item label="上班时间" label-width="120px" prop="advanceStartTime">
            <el-time-select placeholder="上班时间" v-model="advance.advanceStartTime" :editable="false" :picker-options="{
              start: '00:00',
              step: '00:30',
              end: '23:30',
              maxTime: advance.advanceEndTime
            }">
            </el-time-select>
          </el-form-item>
          <!-- 下班时间 -->
          <el-form-item label="下班时间" label-width="120px" prop="advanceEndTime">
            <el-time-select placeholder="下班时间" v-model="advance.advanceEndTime" :editable="false" :picker-options="{
              start: '00:00',
              step: '00:30',
              end: '23:30',
            }">
            </el-time-select>
          </el-form-item>
        </section>
        <section class="form-footer">
          <el-button type="primary" @click="onSubmitTime('form-time')">保存</el-button>
        </section>
      </el-form>
    </el-dialog>

  </div>
</template>

<script>
import {
  getPeopleList,
  getPeopleAdd,
  getPeopleEdit,
  getStartStopPeople,
  getQrCode,
  getAddAdvance
} from "@/api/serve/serve"
import {
  getShopInfo,
} from "@/api/shop/shopInfo"
import { ObjToOpt } from "@/utils/utils";
import { merchantStatusObj, peopleStatusObj, peopleStatusColor } from "@/db/objs";
import { rules } from "@/db/rules";
import vueQr from 'vue-qr';
export default {
  components: {
    vueQr,
  },
  data() {
    return {
      userAvatar:
        "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
      ObjToOpt,
      merchantStatusObj,
      peopleStatusObj,
      peopleStatusColor,
      // table表格
      currentPage: 1, // 当前页
      total: 0, // 总条数  
      tableData: [],//表格数据
      tableParams: {
        page: 1,
        pageSize: 10,
        kw: "",//查询关键字
        status: "",//店铺ID
        merchantId: "",//商户ID
        shopId: "",//店铺ID
      },
      // 弹框
      editShow: false, //弹框开关
      editTitle: "",//弹框标题
      // from表单
      rules,
      params: {
        merchantId: "",//商户ID
        shopId: "",//店铺ID
        nickName: "",
        status: 1,
        mobile: "",
      },
      // 扫码绑定
      bindShow: false,
      imageUrl: require("../../assets/images/mine/logo.png"),
      codeText: "",
      // 设置上班时间
      timeShow: false,
      advance: {
        advanceStartTime: '',
        advanceEndTime: '',
      },

    }
  },
  created() {
    let merchantShopId = window.localStorage.getItem('playOne-store-merchantShopId');
    if (merchantShopId) {
      this.tableParams.merchantId = JSON.parse(merchantShopId)[0];
      this.tableParams.shopId = JSON.parse(merchantShopId)[1];
      this.params.merchantId = JSON.parse(merchantShopId)[0];
      this.params.shopId = JSON.parse(merchantShopId)[1];
    }
  },
  mounted() {
    this.getPeopleList();// 【请求】表格数据
  },
  methods: {
    // 【请求】表格数据
    getPeopleList() {
      let data = this.tableParams;
      getPeopleList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },

    // 【请求】门店信息
    getShopInfo() {
      let data = {
        shopId: this.params.shopId
      };
      getShopInfo(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.advance = res.data;
        }
      });
    },

    // 【请求】添加服务
    getPeopleAdd() {
      let data = this.params;
      getPeopleAdd(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
            message: res.message,
            type: "success",
          });
          this.editShow = false;
          this.reload();// 【请求】表格数据
        }
      });
    },

    // 【请求】编辑服务
    getPeopleEdit() {
      let data = this.params;
      getPeopleEdit(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
            message: res.message,
            type: "success",
          });
          this.editShow = false;
          this.getPeopleList();// 【请求】表格数据
        }
      });
    },

    // 【请求】获取用户二维码
    getQrCode() {
      let data = this.params;
      getQrCode(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.bindShow = true;
          this.codeText = res.data;
        }
      });
    },

    // 【请求】启用停用服务人员
    getStartStopPeople(data) {
      getStartStopPeople(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
            message: res.message,
            type: "success",
          });
          this.getPeopleList();// 【请求】表格数据
        }
      });
    },

    // 【请求】设置上班时间
    getAddAdvance() {
      let data = this.advance;
      getAddAdvance(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.timeShow = false;
          this.$message({
            message: res.message,
            type: "success",
          });
        }
      });
    },


    // 【监听】表格模糊查询
    kwChange: _.debounce(function () {
      this.reload();
    }, 500),

    // 【监听】表格重载
    reload() {
      this.tableParams.page = 1;
      this.currentPage = 1;
      this.getPeopleList();
    },

    // 【监听】表格分页点击
    onCurrentPage(page) {
      this.tableParams.page = page;
      this.getPeopleList();
    },

    // 【监听】表格条数点击
    onSizeChange(pageSize) {
      this.tableParams.pageSize = pageSize;
      this.reload();
    },

    // 【监听】启用
    onStart(row) {
      this.$confirm('你确定要启用吗?', '启用', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      }).then(() => {
        let data = this.params = JSON.parse(JSON.stringify(row));
        data.status = 1;
        this.getStartStopPeople(data)
      });
    },

    // 【监听】禁用
    onStop(row) {
      this.$confirm('你确定要停用吗?', '停用', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      }).then(() => {
        let data = this.params = JSON.parse(JSON.stringify(row));
        data.status = 2;
        this.getStartStopPeople(data)
      });
    },

    // 【监听】新增
    onAdd() {
      this.editShow = true;
      this.editTitle = "新增服务人员";
      this.params = {
        merchantId: this.params.merchantId,//店铺ID
        shopId: this.params.shopId,//店铺ID
        nickName: "",
        status: 1,
        mobile: "",
      };
      this.$nextTick(() => {
        this.$refs["form-ref"].clearValidate();
      });
    },

    // 【监听】编辑
    onEdit(row) {
      this.editShow = true;
      this.editTitle = "编辑服务人员";
      this.params = JSON.parse(JSON.stringify(row));
      this.$nextTick(() => {
        this.$refs["form-ref"].clearValidate();
      });
    },

    // 【监听】扫码绑定
    onBind(row) {
      this.params = JSON.parse(JSON.stringify(row));
      this.getQrCode();
    },

    // 【监听】已经关注
    onFollowed() {
      this.bindShow = false;
      this.getPeopleList();
    },

    // 【监听】表单提交
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.params.cleanId) {
            this.getPeopleEdit();
          } else {
            this.getPeopleAdd();
          }
        } else {
          return false;
        }
      });
    },

    // 【监听】上班时间设置
    onTime() {
      this.timeShow = true;
      // this.advance = {
      //   merchantId: this.params.merchantId,//店铺ID
      //   shopId: this.params.shopId,//店铺ID
      //   advanceStartTime: "",
      //   advanceEndTime: "",
      // };
      this.getShopInfo();
      this.$nextTick(() => {
        this.$refs["form-time"].clearValidate();
      });
    },

    // 【监听】表单提交
    onSubmitTime(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.advance);
          this.getAddAdvance();
        } else {
          return false;
        }
      });
    },

  }
}
</script>

<style lang="scss" scoped>
.people-list {
  padding: .15rem;
}

.table-footer {
  margin-top: .1rem;
  display: flex;
  justify-content: flex-end;
}

.table-search {
  display: flex;
  justify-content: space-between;
  margin-bottom: .15rem;
}

.search-L {
  display: flex;
}

.form-footer {
  display: flex;
  justify-content: flex-end;
}

.code {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.clear-head {
  width: .28rem;
  height: .28rem;
  border-radius: 50%;
  margin-right: .1rem;
}
</style>

<style>
.people-list .nike-name .cell {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>